import {useMediaListStore} from "~/stores/media-list"
import {useDomainDataStore} from "~/stores/domain"
import {useUserStore} from "~/stores/user"

export default defineNuxtRouteMiddleware(async (to, from) => {
    const user = useUserStore()

    setPageLayout(user.isAuthenticated ? 'default' : 'blank')
    to.meta.isMediaBrowser = user.isAuthenticated

    if (user.isAuthenticated) {
        await useMediaListStore().getNode(useDomainDataStore().domainData.tree.id)
    }
})
